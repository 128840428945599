<template>
  <app-dialog
    v-model="dialogForFields"
    :value.sync="dialogForFields"
    width="1124"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'o-hidden v-dialog__form' : 'v-dialog__form'"
    persistent
    @close="dialogForFields = false">
    <v-card
      v-if="dialogForFields">
      <v-card-title>
        Reference Fields
        <v-icon
          color="#fff"
          @click="dialogForFields = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-0">
          <v-col
            md="5"
            cols="12">
            <FieldsControl
              :default-fields="items"
              :button-titles="actionButtons"
              :show-cancel="false"
              :show-save="false"
              disabled-drag-list
              :product-specific-fields="productSpecificFields"
              :selected-fields="selected"
              @changeSelected="changedSelected = $event" />
          </v-col>
          <v-col
            v-if="productToUpdate"
            md="7"
            class="product-preview-wrapper"
            cols="12">
            <app-search-rows-provider
              :items="selectedSchema"
              cols="11"
              placeholder="Search by field name"
              wrapper-class="search-component__wrapper-equal"
              search-wrapper-class="search-items__wrapper-reference"
              input-class="mb-0 pt-0">
              <template #items="{ searchItems }">
                <ProductCellFormList
                  :headers="searchItems"
                  :item="productToUpdate"
                  is-product-preview
                  view="productCreate" />
              </template>
            </app-search-rows-provider>
            <div
              v-if="!showSpinner"
              class="product-preview-wrapper__actions">
              <v-btn
                outlined
                color="lightBlue"
                class="mr-2"
                @click="dialogForFields = false">
                Cancel
              </v-btn>
              <v-btn
                color="lightBlue"
                :disabled="disabledSaveButton"
                @click="addToProject">
                {{ actionButtons.saveButton }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import FieldsControl from '@/components/ProjectDetails/ProjectDetailControlFields';
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellCollectionsFormList';
import ScheduleCells from '@/services/graphql/scheduleCells';
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import {
  mapState, mapGetters,
} from 'vuex';
export default {
  name: 'ProductCellFieldProductReferenceAdding',
  components: {
    FieldsControl,
    ProductCellFormList,
    AppSearchRowsProvider,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productSpecificFields: [],
      actionButtons: {
        saveButton: 'add to Project',
        selectAll: 'select all',
        deselectAll: 'deselect all',
        selectSpecificFields: 'select product specific fields',
      },
      changedSelected: [],
    };
  },
  computed: {
    ...mapState(['showSpinner']),
    ...mapState('Collections', ['productToUpdate']),
    ...mapGetters('Collections', ['selectedSchema']),
    disabledSaveButton() {
      return !this.changedSelected.length;
    },
    dialogForFields: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('manageDialog', val);
      },
    },
  },
  watch: {
    dialogForFields(val) {
      if (val && !this.productSpecificFields.length) {
        this.getProductReferencePreselectedFields();
      }
    },
  },
  methods: {
    addToProject() {
      this.$emit('selectFields', this.changedSelected);
    },
    async getProductReferencePreselectedFields() {
      try {
        const { data } = await ScheduleCells.getProductReferencePreselectedFields();
        this.productSpecificFields = data.response || [];
      } catch (err) {
        console.log('getProductReferencePreselectedFields', err);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-card__text {
  padding-bottom: 96px !important;
}
.product-preview-wrapper {
  height: calc(100vh - 230px);
  padding-bottom: 0;
  @media only screen and (max-width: 959px) {
    height: auto;
  }
  &__actions {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  ::v-deep .item-wrapper {
    margin-bottom: 24px;
  }
}
.v-text-field {
  min-height: 90px;
  overflow: auto;
  max-height: 200px;
}
::v-deep .fields-control {
  &__wrapper {
    height: calc(100vh - 420px);
    @media only screen and (max-width: 959px) {
      height: auto;
    }
  }
  .action-buttons {
    justify-content: flex-start;
  }
}
</style>
